import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = (props) => {
  const services = props.data.services.edges;

  return (
    <Layout bodyClass="page-home">
      <SEO title="Home"/>
      <Helmet>
        <meta
          name="description"
          content="Personal training, fitness, Muscle Activation and general nutrition site. Business located in Beaverton, OR."
        />
      </Helmet>
      <div className="jumbotron jumbotron-fluid pb-5"/>
      <div className="container hero-intro ">
        <div className="row align-content-center justify-content-center">
          <div className="col-12">
            <h1 className="text-center text-capitalize brand">
              Empowering you to achieve and maintain physical and mental fitness.
            </h1>
          </div>
        </div>
      </div>
      <div className="container pt-8 pt-md-10 ">
        <div className="row justify-content-center">
          <Call button/>
        </div>
      </div>
      <div className="container pt-8 pt-md-10">

        <hr/>
        <div className="row justify-content-start box-shadow ">
          <div className="col-12 ">
            <h2 className="title-3 text-dark mb-3">Our Services</h2>
          </div>
          {services.map(edge => (
            <div key={edge.node.id} className="col-12 col-md-3 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <div className="icon">
                    <i className={'fas fa-' + edge.node.data.fa_icon}></i>
                  </div>
                  <h2>
                    <Link to={`/services/${edge.node.uid}`} >{edge.node.data.title.text}</Link>
                  </h2>
                  <p>{edge.node.data.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    services: allPrismicServices(
        sort: {fields: [data___order], order: ASC}
      ) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
            fa_icon
            description
          }
        }
      }
    }
  }
`;

export default Home;
